import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import moment from 'moment'

import ApplicationLayout from 'components/layouts/preliminary-application-layout'
import { usePreliminaryApplication } from 'contexts/preliminary-application-context'
import PersonalForm from 'components/pages/preliminary-application/personal-information-form'

export default ({ location }) => {
  const application = usePreliminaryApplication()
  const [formData, setFormData] = useState(undefined)
  const [errors, setErrors] = useState(undefined)

  useEffect(() => {
    if (application.initialized) {
      application.setStep('personalInformation')
      setFormData({ ...application.personalInformation.fields })
      setErrors({ ...application.personalInformation.errors })
    }
  }, [application.initialized])

  const handleFormChange = event => {
    const { name, value } = event.target
    // console.log('name', name, 'value', value)

    switch (name) {
      case 'dob':
        setFormData({
          ...formData,
          [name]: moment(value).toDate(),
        })
        break

      case 'zip':
        setFormData({
          ...formData,
          [name]: parseInt(value),
        })
        break

      case 'isCitizen':
      case 'havePartners':
        setFormData({
          ...formData,
          [name]: value === 'true',
          partners: [],
        })
        break

      case 'partner1name':
      case 'partner1email':
      case 'partner2name':
      case 'partner2email':
        const index = parseInt(name.slice(7, 8)) - 1
        const prop = name.slice(8)
        const partners = formData.partners ?? []

        setFormData({
          ...formData,
          [name]: value,
          partners: [
            ...partners.slice(0, index),
            {
              ...partners[index],
              [prop]: value,
            },
            ...partners.slice(index + 1),
          ],
        })
        break

      default:
        setFormData({ ...formData, [name]: value })
    }
  }

  const handleFormSubmit = async event => {
    event.preventDefault()
    console.log('handleFormSubmit')

    try {
      await application.update('personalInformation', formData)
      navigate('/preliminary-application/business-experience')
    } catch (error) {
      // TODO:
      console.log('Un-handled error', error)
      window.alert('An error occurred')
    }
  }

  console.log('form', formData)

  if (!application.initialized || !formData) {
    return <div>Loading...</div>
  }

  return (
    <ApplicationLayout
      step="Personal Information"
      stepNumber={1}
      location={location}
    >
      <PersonalForm
        formData={formData}
        errors={errors}
        onChange={handleFormChange}
        onSubmit={handleFormSubmit}
      />
    </ApplicationLayout>
  )
}
