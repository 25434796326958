import React from 'react'
import moment from 'moment'
import tw, { css } from 'twin.macro'

import ContainedButton from 'components/buttons/contained-button'
import Input, { InputGroup } from 'components/input'
import Dropdown from 'components/dropdown'
import CountryDropdown from 'components/dropdown/country-dropdown'
import StateDropdown from 'components/dropdown/state-dropdown'
import YesNoDropdown from 'components/dropdown/yesno-dropdown'

export default ({ formData, errors, onChange, onSubmit }) => {
  return (
    <form onChange={onChange} onSubmit={onSubmit}>
      <InputGroup>
        <div className="md:grid grid-flow-col md:gap-x-4">
          <Input
            label="First Name"
            name="firstName"
            type="text"
            error={errors.firstName}
            defaultValue={formData.firstName}
            required
          />
          <Input
            label="Middle Name"
            name="middleName"
            type="text"
            error={errors.middleName}
            defaultValue={formData.middleName}
          />
          <Input
            label="Last Name"
            name="lastName"
            type="text"
            error={errors.lastName}
            defaultValue={formData.lastName}
            required
          />
        </div>
      </InputGroup>

      <div className="max-w-2xl md:flex flex-row md:space-x-4">
        <Input
          label="Date of Birth"
          name="dob"
          type="date"
          max={moment().subtract(1, 'd').format('YYYY-MM-DD')}
          error={errors.dob}
          defaultValue={
            formData.dob ? moment(formData.dob).format('YYYY-MM-DD') : undefined
          }
          required
        />

        <div className="flex-1">
          <Input
            label="Email"
            name="email"
            type="email"
            error={errors.email}
            defaultValue={formData.email}
            required
          />
        </div>
      </div>

      <InputGroup label="Phone" required>
        <div className="flex flex-row">
          <Dropdown
            prompt={'Select type'}
            name="phoneType"
            className="mr-4"
            error={errors.phoneType}
            defaultValue={formData.phoneType}
            required
          >
            <option value="home">Home</option>
            <option value="work">Work</option>
            <option value="mobile">Mobile</option>
          </Dropdown>

          <div className="flex-1 md:flex-initial">
            <Input
              name="phone"
              type="phone"
              maxLength={13}
              required
              error={errors.phone}
              defaultValue={formData.phone}
            />
          </div>
        </div>
      </InputGroup>

      <div className="max-w-2xl">
        <Input
          label="Address"
          name="address"
          required
          error={errors.address}
          defaultValue={formData.address}
        />
        <Input
          label="Address line 2"
          name="address2"
          error={errors.address2}
          defaultValue={formData.address2}
        />
        <div className="md:flex flex-row md:space-x-4">
          <CountryDropdown
            label="Country"
            name="country"
            error={errors.country}
            defaultValue={formData.country}
            required
          />
          <StateDropdown
            label="State/Province"
            name="state"
            countryName={formData.country}
            error={errors.state}
            defaultValue={formData.state}
            required
          />
        </div>
        <div className="md:flex flex-row md:space-x-4">
          <Input
            label="City"
            name="city"
            required
            error={errors.city}
            defaultValue={formData.city}
          />
          <Input
            label="Zip/Postal code"
            name="zip"
            type="number"
            required
            error={errors.zip}
            defaultValue={formData.zip}
          />
        </div>
        <YesNoDropdown
          label="Are you a permanent citizen of this address?"
          prompt={'Select answer'}
          name="isCitizen"
          error={errors.isCitizen}
          required
          defaultValue={formData.isCitizen}
        />

        {formData.isCitizen === false && (
          <Input
            label="If no, please explain"
            name="notCitizenReason"
            error={errors.notCitizenReason}
            defaultValue={formData.notCitizenReason}
            required
          />
        )}
      </div>

      <YesNoDropdown
        className="max-w-2xl"
        label="Will you have partners in this business?"
        prompt={'Select answer'}
        name="havePartners"
        defaultValue={formData.havePartners}
        // defaultValue={formData.havePartners ? 'Yes' : 'No'}
        required
      />

      {formData.havePartners && (
        <InputGroup
          label="Please provide the name and email address for each partner"
          styles={[
            css`
              > div {
                ${tw`md:grid grid-flow-col grid-cols-2 gap-x-4`}
              }
            `,
          ]}
        >
          <p className="ml-3 mb-2 text-red-700">{`NOTE: Each partner must complete a separate application`}</p>
          <div>
            <Input
              label="Partner 1"
              name="partner1name"
              required
              defaultValue={(formData.partners[0] ?? {}).name}
            />
            <Input
              label="Partner 1 Email"
              name="partner1email"
              required
              defaultValue={(formData.partners[0] ?? {}).email}
            />
          </div>
          <div>
            <Input
              label="Partner 2"
              name="partner2name"
              defaultValue={(formData.partners[1] ?? {}).name}
            />
            <Input
              label="Partner 2 Email"
              name="partner2email"
              required={
                (formData.partners[1] ?? {}).name !== null &&
                (formData.partners[1] ?? {}).name !== ''
              }
              defaultValue={(formData.partners[1] ?? {}).email}
            />
          </div>
        </InputGroup>
      )}

      <div className="flex flex-row justify-end">
        <ContainedButton>Continue</ContainedButton>
      </div>
    </form>
  )
}
