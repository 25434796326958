import React from 'react'
import csc from 'country-state-city'
import PropTypes from 'prop-types'
import Dropdown, { propTypes } from './index'

const StateDropdown = ({ countryId, countryName, ...props }) => {
  const states = csc.getStatesOfCountry(
    countryId ?? csc.getAllCountries().find(c => c.name === countryName)?.id
  )

  return (
    <Dropdown {...props}>
      <option disabled value="" className="text-gray-400">
        Select a state/province
      </option>
      {states.map(state => (
        <option key={state.id} value={state.name}>
          {state.name}
        </option>
      ))}
    </Dropdown>
  )
}

StateDropdown.propTypes = {
  ...propTypes,
  countryId: PropTypes.string,
  countryName: PropTypes.string,
}

export default StateDropdown
